import linelayer from '../../assets/mixed-lines.svg';
import '../../styles/hero.css';

const Hero = () => {
    return (
        <>
            <div className="w-full min-h-[440px]  lg:mt-24 relative"> {/* Add more margin for larger screens */}
                {/* <img src={linelayer} alt="" className="absolute top-0 left-0 w-full h-full -z-50 object-cover" /> */}
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center px-4 md:px-0">
                    <div className="block text-center">
                        <button className="tag-container font-inter font-semibold text-[12px] sm:text-[14px] md:text-[16px] uppercase">
                            Engage Smarter, Respond Faster
                        </button>
                        <div className="relative mt-6 sm:mt-8 md:mt-9 flex justify-center">
                            {/* <h1 className="font-bold font-inter text-[24px] sm:text-[30px] md:text-[48px] lg:text-[54px] leading-[36px] sm:leading-[44px] md:leading-[72px] lg:leading-[80px] text-center w-full sm:w-[90%] md:w-[90%] lg:w-[60%] xl:w-[90%]">
                                Automate, Assist, and Boost Sales with MagBot.ai – Your 24/7 <span className="text-[#6E58F1]">eCommerce Chatbot Solution!</span>
                            </h1> */}
                            <h1 className="font-bold font-inter text-[24px] sm:text-[30px] md:text-[48px] lg:text-[54px] leading-[36px] sm:leading-[44px] md:leading-[72px] lg:leading-[80px] text-center w-full sm:w-[90%] md:w-[90%] lg:w-[80%] xl:w-[90%]">
                            The Future of eCommerce with Agentic AI – Automate, Assist & Scale with  <a href="http://magbot.ai/" className="text-[#6E58F1]"> MagBot.ai</a>!
                                </h1>
                        </div>
                        <div className="relative mt-6 sm:mt-8 md:mt-9 flex justify-center">
                            <p className="text-[#4A4E6D] font-inter text-[12px] sm:text-[14px] md:text-[16px] lg:text-[19px] font-normal text-center w-full sm:w-[100%] md:w-[90%] lg:w-[80%] xl:w-[60%]">
                                MagBot.ai lets you automate anything within your eCommerce store, helping you capture every sale—from instant support to abandoned cart recovery—around the clock.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
