import '../../styles/price.css'

const PriceCard = ({ scrolltocontact }) => {
  return (
    <div className='mt-[50px] flex flex-col items-center md:flex-row md:justify-center gap-4'>
      {' '}
      {/* Card 1 */}
      <div className='group bg-white font-inter pt-8 pb-8 px-5 w-full sm:w-80 md:w-[384px] h-auto rounded-lg shadow-[0px_4px_10px_rgba(0,0,0,0.25)] flex flex-col justify-between border border-transparent hover:bg-gradient-to-t hover:from-[#7C50DD] hover:to-[#7C50DD] hover:border-[#C6C6C8] transition-transform duration-300 transform hover:scale-105'>
        <div>
          <div className='block h-[6rem]'>
            <h1 className='text-[#242536] font-[700] text-[12px] uppercase group-hover:text-white'>
            Professional 
            </h1>
            <div className='mt-3 flex gap-3 items-center justify-start'>
              <h2 className='font-[600] text-[#242536] text-[36px] group-hover:text-white'>
                $99
              </h2>
              <span className='text-[16px] leading-[22px] font-[600] group-hover:text-white'>
                /month
              </span>
            </div>
            <span className='text-[12px] group-hover:text-white'>
              Limited time period offer*
            </span>
          </div>
          <hr className='mt-5 border-[#54545657] group-hover:border-white' />
          <ul className='mt-10'>
            {[
              'Unlimited Store',
              'Unlimited Chats',
              'Bot Customization',
              'AI Assist',
              'Product Recommendation',
              'Order Assist',
              'Ticket Management',
              'Chat Management',
              'Analytics'
            ].map((item, index) => (
              <li
                key={index}
                className='flex items-center mt-5 group-hover:text-white'
              >
                <svg
                  width='17'
                  height='13'
                  viewBox='0 0 17 13'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.61592 10.1735L15.2973 0.980469L16.6336 2.39447L6.61592 13.0015L0.605469 6.63747L1.94092 5.22347L6.61592 10.1735Z'
                    fill='#474646'
                    className='group-hover:fill-white'
                  />
                </svg>
                <p className='ml-4 text-[14px] group-hover:text-white'>
                  {item}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className='flex justify-center'>
          <button
            className='flex justify-center items-center mt-10 gap-2 pt-[10px] pb-[10px] px-[20px] bg-[#6E58F1] text-white font-[600] leading-[22px] text-[16px] rounded-[8px] group-hover:bg-white group-hover:text-[#000000] transition-all duration-300'
            onClick={() => scrolltocontact()}
          >
            Get Started
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='transition-all duration-300'
            >
              <path
                className='fill-white group-hover:fill-[#000000]'
                d='M12.4185 9.38722L6.94972 14.856C6.89891 14.9068 6.83859 14.9471 6.7722 14.9746C6.70581 15.0021 6.63466 15.0162 6.5628 15.0162C6.49095 15.0162 6.41979 15.0021 6.35341 14.9746C6.28702 14.9471 6.2267 14.9068 6.17589 14.856C6.12508 14.8052 6.08477 14.7448 6.05728 14.6785C6.02978 14.6121 6.01562 14.5409 6.01562 14.4691C6.01562 14.3972 6.02978 14.326 6.05728 14.2597C6.08477 14.1933 6.12508 14.133 6.17589 14.0821L11.2584 9.0003L6.17589 3.91847C6.07327 3.81585 6.01562 3.67667 6.01562 3.53155C6.01563 3.38643 6.07327 3.24726 6.17589 3.14464C6.27851 3.04202 6.41768 2.98438 6.5628 2.98438C6.70792 2.98437 6.8471 3.04202 6.94972 3.14464L12.4185 8.61339C12.4693 8.66418 12.5097 8.72449 12.5372 8.79088C12.5647 8.85727 12.5789 8.92844 12.5789 9.0003C12.5789 9.07217 12.5647 9.14333 12.5372 9.20972C12.5097 9.27611 12.4693 9.33643 12.4185 9.38722Z'
              />
            </svg>
          </button>
        </div>
      </div>
      {/* Card 2 */}
      {/* <div className="group bg-white font-inter pt-8 pb-8 px-5 w-full sm:w-80 md:w-[384px] h-[574px] sm:h-[574px] rounded-lg shadow-md flex flex-col justify-between border border-transparent hover:bg-gradient-to-t hover:from-[#7C50DD] hover:to-[#7C50DD] hover:border-[#C6C6C8] transition-transform duration-300 transform hover:scale-105">
                <div>
                    <div className="block h-[6rem]">
                        <h1 className="text-[#242536] font-[700] text-[12px] uppercase group-hover:text-white">Standard</h1>
                        <div className="mt-3 flex gap-3 items-center justify-start">
                            <h2 className="font-[600] text-[#242536] text-[16px] mt-3 group-hover:text-white">Ask us</h2>
                        </div>
                    </div>
                    <hr className="mt-5 border-[#54545657] group-hover:border-white" />
                    <ul className="mt-10">
                        {['1000 credits', 'Data Input: Limited', 'AI Analysis: Basic', 'Blockchain Integration: Standard', 'Support: Email'].map((item, index) => (
                            <li key={index} className="flex items-center mt-5 group-hover:text-white">
                                <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.61592 10.1735L15.2973 0.980469L16.6336 2.39447L6.61592 13.0015L0.605469 6.63747L1.94092 5.22347L6.61592 10.1735Z" fill="#474646" className="group-hover:fill-white" />
                                </svg>
                                <p className="ml-4 text-[14px] group-hover:text-white">{item}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="flex justify-center">
                    <button className="flex justify-center items-center gap-2 pt-[10px] pb-[10px] px-[20px] bg-[#6E58F1] text-white font-[600] leading-[22px] text-[16px] rounded-[8px] group-hover:bg-white group-hover:text-[#000000] transition-all duration-300"
                    onClick={()=>scrolltocontact()}
                    >
                        Get Started
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="transition-all duration-300">
                            <path className="fill-white group-hover:fill-[#000000]" d="M12.4185 9.38722L6.94972 14.856C6.89891 14.9068 6.83859 14.9471 6.7722 14.9746C6.70581 15.0021 6.63466 15.0162 6.5628 15.0162C6.49095 15.0162 6.41979 15.0021 6.35341 14.9746C6.28702 14.9471 6.2267 14.9068 6.17589 14.856C6.12508 14.8052 6.08477 14.7448 6.05728 14.6785C6.02978 14.6121 6.01562 14.5409 6.01562 14.4691C6.01562 14.3972 6.02978 14.326 6.05728 14.2597C6.08477 14.1933 6.12508 14.133 6.17589 14.0821L11.2584 9.0003L6.17589 3.91847C6.07327 3.81585 6.01562 3.67667 6.01562 3.53155C6.01563 3.38643 6.07327 3.24726 6.17589 3.14464C6.27851 3.04202 6.41768 2.98438 6.5628 2.98438C6.70792 2.98437 6.8471 3.04202 6.94972 3.14464L12.4185 8.61339C12.4693 8.66418 12.5097 8.72449 12.5372 8.79088C12.5647 8.85727 12.5789 8.92844 12.5789 9.0003C12.5789 9.07217 12.5647 9.14333 12.5372 9.20972C12.5097 9.27611 12.4693 9.33643 12.4185 9.38722Z" />
                        </svg>
                    </button>
                </div>
            </div> */}
      {/* card 3 */}
      {/* <div className="group bg-white font-inter pt-8 pb-8 px-5 w-full sm:w-80 md:w-[384px] h-[574px] sm:h-[574px] rounded-lg shadow-md flex flex-col justify-between border border-transparent hover:bg-gradient-to-t hover:from-[#7C50DD] hover:to-[#7C50DD] hover:border-[#C6C6C8] transition-transform duration-300 transform hover:scale-105">
                <div>
                    <div className="block h-[6rem]">
                        <h1 className="text-[#242536] font-[700] text-[12px] uppercase group-hover:text-white">Standard</h1>
                        <div className="mt-3 flex gap-3 items-center justify-start">
                            <h2 className="font-[600] text-[#242536] text-[16px] mt-3 group-hover:text-white">Ask us</h2>
                        </div>
                    </div>
                    <hr className="mt-5 border-[#54545657] group-hover:border-white" />
                    <ul className="mt-10">
                        {['Unlimited credits', 'Advanced customization options', 'Tailored to your business needs', '24/7 Email & Chat Support', 'Dedicated account manager'].map((item, index) => (
                            <li key={index} className="flex items-center mt-5 group-hover:text-white">
                                <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.61592 10.1735L15.2973 0.980469L16.6336 2.39447L6.61592 13.0015L0.605469 6.63747L1.94092 5.22347L6.61592 10.1735Z" fill="#474646" className="group-hover:fill-white" />
                                </svg>
                                <p className="ml-4 text-[14px] group-hover:text-white">{item}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="flex justify-center">
                    <button onClick={()=>scrolltocontact()} className="flex  justify-center items-center gap-2 pt-[10px] pb-[10px] px-[20px] bg-[#6E58F1] text-white font-[600] leading-[22px] text-[16px] rounded-[8px] group-hover:bg-white group-hover:text-[#000000] transition-all duration-300">
                        Get Started
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="transition-all duration-300">
                            <path className="fill-white group-hover:fill-[#000000]" d="M12.4185 9.38722L6.94972 14.856C6.89891 14.9068 6.83859 14.9471 6.7722 14.9746C6.70581 15.0021 6.63466 15.0162 6.5628 15.0162C6.49095 15.0162 6.41979 15.0021 6.35341 14.9746C6.28702 14.9471 6.2267 14.9068 6.17589 14.856C6.12508 14.8052 6.08477 14.7448 6.05728 14.6785C6.02978 14.6121 6.01562 14.5409 6.01562 14.4691C6.01562 14.3972 6.02978 14.326 6.05728 14.2597C6.08477 14.1933 6.12508 14.133 6.17589 14.0821L11.2584 9.0003L6.17589 3.91847C6.07327 3.81585 6.01562 3.67667 6.01562 3.53155C6.01563 3.38643 6.07327 3.24726 6.17589 3.14464C6.27851 3.04202 6.41768 2.98438 6.5628 2.98438C6.70792 2.98437 6.8471 3.04202 6.94972 3.14464L12.4185 8.61339C12.4693 8.66418 12.5097 8.72449 12.5372 8.79088C12.5647 8.85727 12.5789 8.92844 12.5789 9.0003C12.5789 9.07217 12.5647 9.14333 12.5372 9.20972C12.5097 9.27611 12.4693 9.33643 12.4185 9.38722Z" />
                        </svg>
                    </button>
                </div>
            </div> */}
    </div>
  )
}

export default PriceCard
