import './App.css';
import Navbar from './component/layout/navbar';
import Main from './component/main/main';
import { useRef, useState, useEffect } from "react";
import Footer from './component/footer/footer';

function App() {
  const priceRef = useRef(null);
  const contactRef = useRef(null);
  const [activeSection, setActiveSection] = useState('');

  const scrollToPricing = () => {
    priceRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleScroll = () => {
    const pricePosition = priceRef.current?.getBoundingClientRect().top;
    const contactPosition = contactRef.current?.getBoundingClientRect().top;
    
    // Change active section based on scroll position
    if (pricePosition < window.innerHeight / 2 && pricePosition > -priceRef.current.offsetHeight) {
      setActiveSection('price');
    } else if (contactPosition < window.innerHeight / 2 && contactPosition > -contactRef.current.offsetHeight) {
      setActiveSection('contact');
    } else {
      setActiveSection('');
    }
  };
  useEffect(() => {
        const HeadingElement = document.getElementById(window.location.hash.replace("#",""));
        HeadingElement?.scrollIntoView({ behavior: "smooth" });
}, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
        <Navbar
          scrollToPricing={scrollToPricing}
          scrollToContact={scrollToContact}
          activeSection={activeSection} // Pass active section to Navbar
        />
      <Main priceRef={priceRef} contactRef={contactRef} />
      <Footer />
    </>
  );
}

export default App;
