import '../../styles/services.css';
import banner from '../../assets/bannerimage.png';
import Industries from "../chatbotservices/industries";

const Services = ({scrollToContact,activesection}) => {
    return (
        <div className="px-4   4k:px-[25%] 4k:mt-[-1%] max-w-screen-4k mx-auto mt-[-2%] ">
            <div className="flex justify-center">
                <button className="demo-btn flex items-center bg-[#5C24FC] pt-[8px] pb-[8px] pl-[16px] pr-[16px] gap-[4px] rounded-[8px] text-white text-[14px] font-medium border-opacity-5 transition-all duration-200 ease-in-out hover:bg-[#4523c7] z-20"
                onClick={()=>scrollToContact()}
                >
                    Schedule a Demo
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.4185 9.38722L6.94972 14.856C6.89891 14.9068 6.83859 14.9471 6.7722 14.9746C6.70581 15.0021 6.63466 15.0162 6.5628 15.0162C6.49095 15.0162 6.41979 15.0021 6.35341 14.9746C6.28702 14.9471 6.2267 14.9068 6.17589 14.856C6.12508 14.8052 6.08477 14.7448 6.05728 14.6785C6.02978 14.6121 6.01562 14.5409 6.01562 14.4691C6.01562 14.3972 6.02978 14.326 6.05728 14.2597C6.08477 14.1933 6.12508 14.133 6.17589 14.0821L11.2584 9.0003L6.17589 3.91847C6.07327 3.81585 6.01562 3.67667 6.01562 3.53155C6.01563 3.38643 6.07327 3.24726 6.17589 3.14464C6.27851 3.04202 6.41768 2.98438 6.5628 2.98438C6.70792 2.98437 6.8471 3.04202 6.94972 3.14464L12.4185 8.61339C12.4693 8.66418 12.5097 8.72449 12.5372 8.79088C12.5647 8.85727 12.5789 8.92844 12.5789 9.0003C12.5789 9.07217 12.5647 9.14333 12.5372 9.20972C12.5097 9.27611 12.4693 9.33643 12.4185 9.38722Z" fill="white" />
                    </svg>
                </button>
            </div>

            <div className="w-full h-auto sm:h-[30rem] flex items-center justify-center mt-[7%] relative">
                
                <div className='block relative'>
                    <img
                        src={banner}
                        alt="Service Banner"
                        className='w-auto sm:min-w-[500px] sm:max-w-[1130px] sm:w-[1350px] h-auto sm:h-[500px] md:h-[499px] object-cover'
                    />
                    <div className='bottom-linear-gradient h-auto  sm:h-[9rem] w-full absolute  sm:bottom-[70px]'></div>
                </div>


            </div>

            <div className='sm:px-8 md:px-16'>
                <Industries />

            </div>
        </div>
    );
};

export default Services;
